"use client";

import { Dropdown, Spinner } from "@zapier/design-system";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";

const playgroundItems = [
  { label: "API Specifications Search", value: "/playground/specifications" },
  { label: "Code Generation", value: "/playground/code" },
  { label: "Test Case Finder", value: "/playground/test-cases" },
  { label: "Issue Diagnosis (v2)", value: "/playground/diagnose-v2" },
  { label: "Test Generation", value: "/playground/test-generation" },
  {
    label: "Create & Test Action",
    value: "/playground/create-and-test-action",
  },
  { label: "Issue Diagnosis", value: "/playground/diagnose" },
].sort((a, b) => a.label.localeCompare(b.label));

const otherItems = [{ label: "API Documentation", value: "/docs" }];

function LinkCards({ items }: { items: { label: string; value: string }[] }) {
  const gridCols =
    items.length === 1
      ? "grid-cols-1"
      : items.length === 2
      ? "grid-cols-1 lg:grid-cols-2"
      : "grid-cols-1 md:grid-cols-2 xl:grid-cols-3";

  return (
    <div
      className="mx-auto w-full"
      style={{ maxWidth: "1300px" }}
    >
      <div className={`grid ${gridCols} gap-x-8 gap-y-6 justify-center`}>
        {items.map((item) => (
          <div
            key={item.value}
            className="mx-auto w-full"
            style={{ maxWidth: "350px" }}
          >
            <Link
              href={item.value}
              className="p-4 border rounded-lg hover:bg-gray-100 hover:shadow-md transition-all w-full flex items-center justify-center text-center"
            >
              <h3 className="text-lg font-medium">{item.label}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function PlaygroundPicker({
  items = playgroundItems,
  view = "dropdown",
}: {
  items?: { label: string; value: string }[];
  view?: "dropdown" | "list";
}) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  if (view === "list") {
    return (
      <div className="w-full space-y-12">
        <LinkCards items={items} />

        <hr className="border-gray-200" />

        <LinkCards items={otherItems} />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-row items-center gap-2">
        <div className="flex-grow">
          <Dropdown
            items={items}
            placeholder="Choose a playground..."
            isDisabled={isLoading}
            onChange={(item) => {
              setIsLoading(true);
              router.push(item.value);
            }}
            autoFocus={true}
            isFullWidth={true}
          />
        </div>
        {isLoading && (
          <div className="flex-shrink-0">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
